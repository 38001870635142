<template>
    <div>
        <header>
            <h1>VALID</h1>
        </header>
        <section class="section1">
            <h2>A gestão de datas de validade na sua mão</h2>
        </section>
        <section class="section2">
            <div class="loader"></div>
            <h3>Sincronizando</h3>
        </section>
        <footer>
            <router-link to="/">Cancelar</router-link>
            <span>Uma tecnologia oferecida por: <img src="../assets/img/logo-minus.png" alt="logo minus" /></span>
        </footer>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import HTTP from '../http/http.js';
import MsgError from '../components/MsgError';
import axios from 'axios';
export default {
    components: {
        MsgError,
    },

    data() {
        return {
            msgE: 'Falha no Login, favor tentar novamente. Se o erro persistir, consulte o suporte.',
            showAlert: false,
        };
    },

    created() {
        let loja = sessionStorage.getItem('loja');

        axios
            .all([HTTP.get(`${loja}/tarefas`), HTTP.get(`${loja}/produtos-geral`), HTTP.get(`${loja}/inclusao`)])
            .then((responseArr) => {
                let tarefasAPI = responseArr[0].data;
                let todosProdutos = responseArr[1].data;
                let produtosAPI = responseArr[2].data;
                let novosProdutos = [];
                produtosAPI[0].map((element) => {
                    element.validadesAdicionais = [];
                    element.validadesAdicionais.push({
                        quantidade: element.quantidade,
                        validade: element.data_validade,
                    });
                    delete element.data_validade;
                    delete element.quantidade;
                    let produtoExiste = novosProdutos.filter(
                        (produto) => produto.codigo_de_barras == element.codigo_de_barras
                    );
                    if (produtoExiste.length > 0) {
                        produtoExiste[0].validadesAdicionais.push(element.validadesAdicionais[0]);
                    } else {
                        novosProdutos.push(element);
                    }
                });

                this.$store.dispatch('inclusao/setProdutos', novosProdutos);
                this.$store.dispatch('tarefas/setTarefas', tarefasAPI);
                this.$store.dispatch('setTodosProdutos', todosProdutos);
                this.$router.push({ name: 'resumo' });
            })
            .catch((error) => {
                this.showAlert = true;
                console.log(error);
            });
    },

    methods: {
        closeError() {
            this.showAlert = false;
        },
    },
};
</script>

<style scoped>
header {
    display: flex;
    padding: 20px;
    place-content: center;
}

header h1 {
    font-size: 4.3rem;
    font-weight: 300;
}

.section1 {
    padding: 40px 60px;
    display: flex;
    place-content: center;
    text-align: center;
    margin-top: 92px;
}

.section1 h2 {
    font-size: 1.3rem;
    font-weight: 400;
}

.section2 {
    display: flex;
    width: 100vw;
    padding: 15px;
    background-color: rgb(110, 110, 110);
    align-items: flex-end;
    position: relative;
}

.section2 h3 {
    color: #fff;
    margin-left: 15px;
}

.progress {
    width: 100%;
}

footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    padding: 10px;
    text-align: center;
    place-items: center;
    background-color: rgb(110, 110, 110);
}

footer a {
    font-size: 0.9rem;
    color: #000;
    padding: 10px;
    margin: 10px 0px;
    border: 1px solid #f4f4f4;
    background-color: #cdcdcd;
    border-radius: 4px;
}

footer a:hover {
    background-color: #fff;
    position: relative;
    top: 5px;
}

footer span img {
    width: 70px;
}

footer span {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.loader {
    animation: is-rotating 1s infinite;
    border: 6px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #51d4db;
    height: 50px;
    width: 50px;
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

@media (max-width: 319px) {
    .section2 {
        margin-top: 0;
    }

    footer a {
        margin: 5px 0;
        padding: 5px;
    }

    footer span {
        font-size: 12px;
    }
}
</style>
